const SET_SAVED_JOBS = "SET_SAVED_JOBS"
const TOGGLE_JOB = "TOGGLE_JOB"

const initialState = {
  savedJobs: {},
  count: 0,
}

export const initSavedJobs = () => ({
  type: SET_SAVED_JOBS,
  data: JSON.parse(localStorage.getItem("savedJobs") || "{}"),
})

export const toggleJob = job => ({
  type: TOGGLE_JOB,
  data: job,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVED_JOBS:
      return { ...state, savedJobs: action.data, count: Object.keys(action.data).length }
    case TOGGLE_JOB: {
      const updState = { ...state, savedJobs: { ...state.savedJobs } }

      if (!!updState.savedJobs[action.data.id]) {
        delete updState.savedJobs[action.data.id]
      } else {
        updState.savedJobs[action.data.id] = { ...action.data, dateSaved: new Date() }
      }

      updState.count = Object.keys(updState.savedJobs).length
      localStorage.setItem("savedJobs", JSON.stringify(updState.savedJobs))
      return updState
    }
    default:
      return state
  }
}
