module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46321101-1","respectDNT":true,"defer":false,"head":false,"anonymize":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"169415726793632"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"guidewire-careers","short_name":"guidewire-careers","start_url":"/","background_color":"#10191d","theme_color":"#26d1a0","display":"minimal-ui","crossOrigin":"use-credentials","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"7d81e21b98b7d2dc6c62fbbf8ea69997"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","redirect":false,"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-Forwarded-For:  3.222.210.42,52.54.213.28,54.210.67.250,52.20.174.116,54.144.92.122,54.156.165.239,52.71.235.33,34.204.177.191"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
